export const standardCanvasWidth = 250;
export const standardCanvasHeight = 250;
export const standardCanvasArea = standardCanvasWidth * standardCanvasHeight;
export const exportTypes = {
  png: { id: 1, name: 'PNG', icon: 'photo' },
  video: { id: 2, name: 'Video', icon: 'videocam' },
  html5: { id: 3, name: 'HTML5', icon: 'code' },
  dv360: { id: 4, name: 'AMP Ads - DV360', icon: 'offline_bolt' },
  gif: { id: 5, name: 'GIF', icon: 'gif_box' },
  jpeg: { id: 6, name: 'JPEG', icon: 'wallpaper' },
  googleAds: { id: 7, name: 'AMP Ads - GoogleAds', icon: 'offline_bolt' }
};

export const downloadFormats =
  [
    exportTypes.png,
    exportTypes.video,
    exportTypes.html5,
    exportTypes.dv360,
    exportTypes.gif,
    exportTypes.jpeg,
    exportTypes.googleAds
  ];

export const ampDownloadTypes = [
  {
    id: 1,
    label: 'Display & Video 360',
    name: 'DV360'
  },
  {
    id: 2,
    label: 'Google Ads',
    name: 'GoogleAds'
  }
]

export const canvasLayouts = {
  MOSAIC: 1,
  LIST: 2,
  CAROUSEL: 3,
  VARIANTS: 4
}

export const zoomExtreme = {
  minZoom: 10,
  maxZoom: 300
}

export const nodeNames = {
  img: 'IMG',
  audio: 'AUDIO',
  video: 'VIDEO'
}

export const editingModes = {
  Single: 1,
  Multiple: 2
}

export const previewModeParams = {
  'isPreviewMode': 'pm',
  'adsPerRow': 'apr',
  'zoom': 'zm',
  'variantType': 'vt',
  'variantId': 'vi',
  'layoutType': 'lt',
  'adVariantParentId': 'av'
}

export const sizeTreeTypes = {
  MultipleChoice: 1,
  SingleChoice: 2,
  LimitedChoice: 3
}

export const commentFilters = {
  ALL: 1,
  UNRESOLVED: 2,
  RESOLVED: 3
}

export const RoimaticCreativeSetCommentsMenu = {
  EDIT_TEMPLATE: 1,
  ADD_NOTE: 2,
  VIEW_NOTES: 3,
  HIDE_NOTES: 4,
  SHOW_COMMENTS: 5,
  HIDE_COMMENTS: 6
}

export const RoimaticCreativeSetStatus = {
  DRAFT: 1,
  DELETE: 3,
  IN_REVIEW: 5,
  PUBLISHED: 6
}

export const RoimaticCreativeSetStatusTypes = [
  { id: RoimaticCreativeSetStatus.DRAFT, title: 'message.CreativeBuilder.draft' },
  { id: RoimaticCreativeSetStatus.IN_REVIEW, title: 'message.CreativeBuilder.inReview', icon: 'mdi-forum-outline' },
  { id: RoimaticCreativeSetStatus.PUBLISHED, title: 'message.CreativeBuilder.published', icon: 'mdi-tune' }
]

export const commentFilterTypes = [
  {
    id: 1,
    name: 'message.all'
  },
  {
    id: 2,
    name: 'message.CreativeBuilder.unresolved'
  },
  {
    id: 3,
    name: 'message.CreativeBuilder.resolved'
  }
]

export const methodOperations = {
  APPLY: 1,
  SET: 2
}
export const fontBaseUrl = `${process.env.VUE_APP_CB_FONTS_URL}`;

// Overrides the object control styling
// http://fabricjs.com/controls-customization
// http://fabricjs.com/docs/fabric.Object.html#cornerSize
export const fabricObjectControls = {
  borderColor: '#197EF1', // Default: rgb(178,204,255)
  borderHoverColor: '#197EF1',
  borderScaleFactor: 1.0, // Default: 1
  maxBorderScaleFactor: 6.0,
  cornerColor: '#FFFFFF', // Default: rgb(178,204,255)
  cornerStrokeColor: '#197EF1', // Switch to #DD1155 for contrasting color
  cornerSize: 13, // Default: 13
  maxCornerSize: 80,
  minCornerSize: 9,
  cornerStyle: 'rect',
  transparentCorners: false, // Default: true
  padding: 0
}

export const originX = {
  left: 'left',
  center: 'center',
  right: 'right'
};

export const originY = {
  top: 'top',
  center: 'center',
  bottom: 'bottom'
};

export const bpseTypes = Object.freeze({
  facebook: 5
});

export const cbRouteNames = {
  BASE: 'creativeBuilder',
  TEMPLATE: 'creativeBuilderTemplate',
  VARIANT: 'creativeBuilderTemplateVariant',
  EXPORT: 'creativeBuilderExport',
  ADD_EXPORT: 'creativeBuilderAddExport',
  CLONE_EXPORT: 'creativeBuilderCloneExport',
  VIEW_EXPORT: 'creativeBuilderViewExport',
  EXPORT_PRESET: 'creativeBuilderExportPreset',
  UNSAVEDVARIANT: 'creativeBuilderVariant',
  EXPORTPREVIEW: 'creativeBuilderExportPreview'
};

export const mediaIcons = {
  image: 'photo',
  video: 'videocam',
  audio: 'music_note'
};

export const templateTypes = {
  Static: 1,
  Video: 2,
  Animation: 3
}