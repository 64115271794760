import { levels } from '../constants/textStyles';

export const minTime = 0;
export const maxTime = 120;
export const minimumDuration = 0.1;
export const direction = { forward: 'forward', backward: 'backward' };
export const timings = { startTime: 'startTime', endTime: 'endTime' };
export const minimumPositionWidth = 1;
export const maxRecordingWait = 0.0;
export const lineSegmentsCount = 10; // 10 hatch marks for each second on ruler.
export const maxDeltaY = 75;
export const adSwitchWait = 500; // In milli sec
export const MIN_TWEEN_DURATION = 0;
export const TWEEN_START_VALUE = 0;
export const TWEEN_END_VALUE = 1;
export const fabricMediaElementIndex = 0; // Media element at this index is used by fabric object.
export const duplicateMediaElementIndex = 1; // Media element at this index is used to get audio during download.
export const lastResetWait = 20;
export const decreaseLetterLevelDuration = 30;
export const defaultFps = 30;
export const defaultAudioBitrate = 128; // kb / s

export const loopTypes = {
  once: 1,
  forever: 100
}
export const foreverLoop = Infinity;

export const download = {
  minOpacityChange: 0.001
}

export const animateConstants = {
  rotateByDegrees: 90,
  spinDeg: 360,
  slideLength: 20,
  minScale: 0,
  maxScale: 1.2,
  minOpacity: 0,
  maxOpacity: 1,
  moveLength: 10,
  middleAnimateDuration: 600,
  moveDuration: 100,
  fontSizeScale: 0.85,
  defaultEase: 'easeLinear'
}

export const typeNames = {
  fade: 'Fade',
  slideUp: 'SlideUp',
  slideDown: 'SlideDown',
  blink: 'Blink',
  move: 'Move',
  ping: 'Ping',
  heartBeat: 'HeartBeat',
  pulsate: 'Pulsate',
  spin: 'Spin',
  slideRight: 'SlideRight',
  slideLeft: 'SlideLeft',
  fadeSlideUp: 'Fade SlideUp',
  fadeSlideDown: 'Fade SlideDown',
  fadeSlideLeft: 'Fade SlideLeft',
  fadeSlideRight: 'Fade SlideRight',
  rotateIn: 'RotateIn',
  rotateOut: 'RotateOut',
  scaleUp: 'ScaleUp',
  scaleDown: 'ScaleDown',
  zoomIn: 'Zoom In',
  typeWriter: 'TypeWriter',
  none: 'None'
}

export const positions = {
  start: 'start',
  middle: 'middle',
  end: 'end'
}

export const starImage = '/img/icons/star.png';

const nonLayerAnimationTypes = {
  start: [
    { name: typeNames.fade },
    { name: typeNames.slideUp },
    { name: typeNames.slideDown },
    { name: typeNames.fadeSlideUp },
    { name: typeNames.fadeSlideDown },
    { name: typeNames.scaleUp },
    { name: typeNames.scaleDown },
    { name: typeNames.zoomIn },
    { name: typeNames.typeWriter },
    { name: typeNames.none }
  ],
  middle: [
    { name: typeNames.blink },
    { name: typeNames.pulsate },
    { name: typeNames.ping },
    { name: typeNames.heartBeat },
    { name: typeNames.none }
  ],
  end: [
    { name: typeNames.fade },
    { name: typeNames.slideUp },
    { name: typeNames.slideDown },
    { name: typeNames.fadeSlideUp },
    { name: typeNames.fadeSlideDown },
    { name: typeNames.scaleUp },
    { name: typeNames.scaleDown },
    { name: typeNames.zoomIn },
    { name: typeNames.typeWriter },
    { name: typeNames.none }
  ]
}

export const animationTypes = {
  [levels.layer]: {
    start: [
      { name: typeNames.fade },
      { name: typeNames.slideUp },
      { name: typeNames.slideDown },
      { name: typeNames.slideRight },
      { name: typeNames.slideLeft },
      { name: typeNames.fadeSlideUp },
      { name: typeNames.fadeSlideDown },
      { name: typeNames.fadeSlideLeft },
      { name: typeNames.fadeSlideRight },
      { name: typeNames.rotateIn },
      { name: typeNames.rotateOut },
      { name: typeNames.scaleUp },
      { name: typeNames.scaleDown },
      { name: typeNames.zoomIn },
      { name: typeNames.none }
    ],
    middle: [
      { name: typeNames.blink },
      { name: typeNames.move },
      { name: typeNames.pulsate },
      { name: typeNames.ping },
      { name: typeNames.heartBeat },
      { name: typeNames.spin },
      { name: typeNames.none }
    ],
    end: [
      { name: typeNames.fade },
      { name: typeNames.slideUp },
      { name: typeNames.slideDown },
      { name: typeNames.slideRight },
      { name: typeNames.slideLeft },
      { name: typeNames.fadeSlideUp },
      { name: typeNames.fadeSlideDown },
      { name: typeNames.fadeSlideLeft },
      { name: typeNames.fadeSlideRight },
      { name: typeNames.rotateIn },
      { name: typeNames.rotateOut },
      { name: typeNames.scaleUp },
      { name: typeNames.scaleDown },
      { name: typeNames.zoomIn },
      { name: typeNames.none }
    ]
  },
  [levels.letter]: nonLayerAnimationTypes,
  [levels.line]: nonLayerAnimationTypes,
  [levels.word]: nonLayerAnimationTypes
}

export const easingFunctions = [
  {
    fabricName: animateConstants.defaultEase,
    ease: 'Linear',
    type: 'None'
  },
  {
    fabricName: 'easeInQuad',
    ease: 'Quadratic',
    type: 'In'
  },
  {
    fabricName: 'easeOutQuad',
    ease: 'Quadratic',
    type: 'Out'
  },
  {
    fabricName: 'easeInOutQuad',
    ease: 'Quadratic',
    type: 'InOut'
  },
  {
    fabricName: 'easeInCubic',
    ease: 'Cubic',
    type: 'In'
  },
  {
    fabricName: 'easeOutCubic',
    ease: 'Cubic',
    type: 'Out'
  },
  {
    fabricName: 'easeInOutCubic',
    ease: 'Cubic',
    type: 'InOut'
  },
  {
    fabricName: 'easeInQuart',
    ease: 'Quartic',
    type: 'In'
  },
  {
    fabricName: 'easeOutQuart',
    ease: 'Quartic',
    type: 'Out'
  },
  {
    fabricName: 'easeInOutQuart',
    ease: 'Quartic',
    type: 'InOut'
  },
  {
    fabricName: 'easeInQuint',
    ease: 'Quintic',
    type: 'In'
  },
  {
    fabricName: 'easeOutQuint',
    ease: 'Quintic',
    type: 'Out'
  },
  {
    fabricName: 'easeInOutQuint',
    ease: 'Quintic',
    type: 'InOut'
  },
  {
    fabricName: 'easeInSine',
    ease: 'Sinusoidal',
    type: 'In'
  },
  {
    fabricName: 'easeOutSine',
    ease: 'Sinusoidal',
    type: 'Out'
  },
  {
    fabricName: 'easeInOutSine',
    ease: 'Sinusoidal',
    type: 'InOut'
  },
  {
    fabricName: 'easeInExpo',
    ease: 'Exponential',
    type: 'In'
  },
  {
    fabricName: 'easeOutExpo',
    ease: 'Exponential',
    type: 'Out'
  },
  {
    fabricName: 'easeInOutExpo',
    ease: 'Exponential',
    type: 'InOut'
  },
  {
    fabricName: 'easeInCirc',
    ease: 'Circular',
    type: 'In'
  },
  {
    fabricName: 'easeOutCirc',
    ease: 'Circular',
    type: 'Out'
  },
  {
    fabricName: 'easeInOutCirc',
    ease: 'Circular',
    type: 'InOut'
  },
  {
    fabricName: 'easeInCubic',
    ease: 'Cubic',
    type: 'In'
  },
  {
    fabricName: 'easeOutCubic',
    ease: 'Cubic',
    type: 'Out'
  },
  {
    fabricName: 'easeInOutCubic',
    ease: 'Cubic',
    type: 'InOut'
  },
  {
    fabricName: 'easeInElastic',
    ease: 'Elastic',
    type: 'In'
  },
  {
    fabricName: 'easeOutElastic',
    ease: 'Elastic',
    type: 'Out'
  },
  {
    fabricName: 'easeInOutElastic',
    ease: 'Elastic',
    type: 'InOut'
  },
  {
    fabricName: 'easeInBack',
    ease: 'Back',
    type: 'In'
  },
  {
    fabricName: 'easeOutBack',
    ease: 'Back',
    type: 'Out'
  },
  {
    fabricName: 'easeInOutBack',
    ease: 'Back',
    type: 'InOut'
  },
  {
    fabricName: 'easeInBounce',
    ease: 'Bounce',
    type: 'In'
  },
  {
    fabricName: 'easeOutBounce',
    ease: 'Bounce',
    type: 'Out'
  },
  {
    fabricName: 'easeInOutBounce',
    ease: 'Bounce',
    type: 'InOut'
  }
]

export const resetProperties = ['top', 'left', 'opacity', 'scaleY', 'scaleX', 'originX', 'originY', 'angle', 'visible', 'styles', 'padding', 'text', 'currentTime'];

export const animationPlayModes = {
  playAll: 'playAll',
  playSelected: 'playSelected',
  playEach: 'playEach'
}

export const stylesProperties = {
  fill: 'fill',
  deltaY: 'deltaY',
  fontSize: 'fontSize'
}

export const animationUpdateTypes = {
  LAYER: 'layer',
  ANIMATION_POSITION: 'animationPosition',
  ANIMATION_SOURCE_POSITION: 'animationSourcePosition'
}