import { AddonsRoute, PacksRoute } from 'Features/addons/router.js';
import { AdhocQueryReportsAffiliateRoute, DashboardAffiliateRoute, TrackingLinkAffiliateRoute, PostbackUrlAffiliateRoute } from 'Router/affiliateRouter';
import { BriefRoute, BriefRouteEdit } from 'Features/briefs/router.js';
import { DashboardRoute, PortalDashboardRoute } from 'Features/dashboard/router.js';
import { FaqRoute, PortalFaqRoute } from 'Features/faq/router.js';
import { HelpArticlesRoute, PortalHelpArticlesRoute } from 'Features/helpArticles/router.js';
import { PortalPublishedReportRoute, PublishedReportRoute } from 'Features/publishedReports/router.js';

import { AdhocQueryReportsRoute } from 'Features/adhocQueryReports/router.js';
import AssetsRoute from 'Features/assets/router.js';
import AudienceRoute from 'Features/audience/router.js';
import { AuthenticationRoute } from 'Features/login/router.js';
import { BriefPlanRoute } from 'Features/briefPlan/router'
import BusinessCategorySelectionRoute from 'Features/businessCategorySelection/router.js';
import CampaignLaunchRoutes from 'Features/campaignLaunch/router.js';
import CbRendererRoute from 'Features/cbRenderer/router.js'
import CreativeBuilderRoutes from 'Features/creativeBuilder/router.js';
import { EmbedRoutes } from 'Features/embed/router'
import { ForgotPasswordRoutes } from 'Features/forgotPassword/router';
import { HomeRoute } from 'Features/home/router.js';
import { InviteUserRoute } from 'Features/inviteUser/router'
import { LocalBusinessRoute } from 'Features/localBusiness/router.js';
import { MediaDataUploadRoute } from 'Features/mediaDataUpload/router.js';
import { TrackingLinkRoute } from 'Features/trackingLink/router.js';
import { OauthRedirectRoute } from 'Features/oauthRedirect/router.js';
import OnboardingRoutes from 'Features/onboarding/router.js';
import { OrganicPromotionLaunchRoute } from 'Features/organicPromotionLaunch/router.js';
import { PageNotFoundRoute } from './pageNotFound';
import PaymentRoutes from 'Features/wallet/router.js';
import { PostbackUrlRoute } from 'Features/postbackUrl/router.js';
import ProductFeedRoute from 'Features/productFeed/router.js';
import ProductInsightsRoute from 'Features/productInsights/router.js'
import { PromotionsRoute } from 'Features/promotions/router.js';
import ReviewManagementDashboard from 'Features/reviewManagement/router.js';
import RichMediaAdBuilderRoute from 'Features/richMediaAdBuilder/router.js';
import RichMediaAdPreviewRoute from 'Features/richMediaAdPreview/router.js';
import RichMediaAdShowcaseRoute from 'Features/richMediaAdShowcase/router.js';
import Router from 'vue-router';
import SignUpRoute from 'Features/signup/router.js';
import StoreSetupRoute from 'Features/storeSetup/router.js';
import { UserEmailVerificationRoute } from 'Features/userEmailVerification/router';
import Vue from 'vue';

//routes
const RootLayout = () =>
  import(/* webpackChunkName: "rootLayout" */ 'Layouts/RootLayout');

const AppLayout = () =>
  import(/* webpackChunkName: "appLayout" */ 'Layouts/AppLayout');

const appRoutes = [
  DashboardRoute,
  BusinessCategorySelectionRoute,
  StoreSetupRoute,
  SignUpRoute,
  AssetsRoute,
  ...PaymentRoutes,
  AudienceRoute,
  ...CampaignLaunchRoutes,
  ProductFeedRoute,
  PublishedReportRoute,
  ProductInsightsRoute,
  PortalDashboardRoute,
  PortalPublishedReportRoute,
  BriefRoute,
  BriefRouteEdit,
  BriefPlanRoute,
  AddonsRoute,
  PacksRoute,
  PostbackUrlRoute,
  AdhocQueryReportsRoute,
  MediaDataUploadRoute,
  TrackingLinkRoute,
  PromotionsRoute,
  DashboardAffiliateRoute,
  AdhocQueryReportsAffiliateRoute,
  PostbackUrlAffiliateRoute,
  TrackingLinkAffiliateRoute,
  ReviewManagementDashboard,
  OrganicPromotionLaunchRoute,
  ...CreativeBuilderRoutes,
  HelpArticlesRoute,
  PortalHelpArticlesRoute,
  FaqRoute,
  PortalFaqRoute
];

const rootLevelRoutes = [];

appRoutes.push(...[RichMediaAdBuilderRoute]);
rootLevelRoutes.push(...[RichMediaAdPreviewRoute, RichMediaAdShowcaseRoute]);

rootLevelRoutes.push(CbRendererRoute);
rootLevelRoutes.push(PageNotFoundRoute);

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: RootLayout,
      redirect: '/login',
      props: (route) => ({ embed: String(route.query.embed).toLowerCase() === 'true' }),
      children: [
        ...OnboardingRoutes,
        HomeRoute,
        {
          path: '/app/:businessProfileId',
          component: AppLayout,
          redirect: '/app/:businessProfileId/dashboard',
          props: (route) => ({ embed: String(route.query.embed).toLowerCase() === 'true' }),
          children: appRoutes
        },
        AuthenticationRoute,
        ...ForgotPasswordRoutes,
        InviteUserRoute,
        UserEmailVerificationRoute,
        OauthRedirectRoute,
        LocalBusinessRoute,
        ...EmbedRoutes
      ]
    },
    {
      path: '/',
      component: RootLayout,
      children: rootLevelRoutes
    }
  ]
});